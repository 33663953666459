import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { ReactComponent as ZnanyLekarz } from '../../assets/svg/znany-lekarz-big.svg';
import { ReactComponent as GoogleCalendar } from '../../assets/svg/googlecalendarlogo.svg';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { AppointmentButtonsProps, ButtonDataProps, ImageProps, MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';
import { useTranslationsContextData } from '../../hooks/use-context-data';

interface TeamMemberTitleProps {
  name: string;
  role: string;
  className?: string;
}
export type TeamMemberCategory = {
  label: string;
  value: string;
};

const TeamMemberTitle: React.FC<TeamMemberTitleProps> = ({
  name,
  role,
  className,
}) => (
  <div className={className}>
    <h1 className="h450 mb-3 md:mb-1">{name}</h1>
    <h2 className="body-s text-green mb-6 md:mb-4">{role}</h2>
  </div>
);

export interface TeamMemberProps {
  photo: ImageProps;
  photoMobile: ImageProps;
  pathname: string;
  slug: string;
  name: string;
  role: string;
  description: MDXProps;
  appointment?: ButtonDataProps;
  appointmentbuttons?: AppointmentButtonsProps[];
  categories?: TeamMemberCategory[];
  id: string;
  locale: string;
}

export const TeamMember: React.FC<TeamMemberProps> = ({
  photo,
  photoMobile,
  name,
  role,
  description,
  appointment,
  appointmentbuttons,
  locale
}) => {
  const translations = useTranslationsContextData();
  const breadcrumbs = useMemo(
    () => [
      {
        name: translations['breadcrumbs.homepage'],
        url: '/',
      },
      {
        name: translations['breadcrumbs.team'],
        url: translations['breadcrumbs.teamUrl'],
      },
      { name: name },
    ],
    [name]
  );

  return (
    <>
      <Breadcrumbs steps={breadcrumbs} />

      <section className="max-w-8xl mx-auto pb-16 px-6 lg:pb-24 lg:px-8 pt-8 lg:pt-20">
        <div className="flex flex-col md:flex-row md:gap-x-14 mb-16 md:mb-24">
          <TeamMemberTitle className="md:hidden" name={name} role={role} />
          <div className="flex-none flex flex-col items-center">
            <div className="hidden md:block w-[20.188rem] h-[31.25rem] overflow-hidden">
              {photo && (
                <GatsbyImage
                  className="min-h-full saturate-50 rounded-3xl"
                  image={photo?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={name}
                  objectPosition="top"
                  objectFit="cover"
                />
              )}
            </div>
            <div className="md:hidden w-full h-0 aspect-w-14 aspect-h-15 overflow-hidden">
              {photoMobile && (
                <div className="translate-z-0 transform">
                  <GatsbyImage
                    className="min-h-full min-w-full saturate-50 rounded-3xl"
                    image={
                      photoMobile?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={name}
                    objectPosition="top"
                    objectFit="cover"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col items-center mt-4">
              {appointment && (
                <>
                  <span className="label-xs text-gray-400 mb-1.5">
                    {appointment.text}
                  </span>
                  <a href={appointment.url} target="_blank" rel="noreferrer">
                    {locale === "en" ? <GoogleCalendar /> : <ZnanyLekarz />}
                  </a>
                </>
              )}
              {appointmentbuttons && !!appointmentbuttons.length && (
                <div className="flex flex-col items-center gap-2 mt-3">
                  {appointmentbuttons.map((button, index) => (
                    <div className="flex flex-col items-center" key={index}>
                      {button?.text && (
                        <span className="label-xs text-gray-400">
                          {button.text}
                        </span>
                      )}
                      <a
                        key={index}
                        href={button.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GatsbyImage
                          className="h-full max-h-10 max-w-52 inline-block"
                          image={
                            button.logo?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={name}
                          objectPosition="top"
                          objectFit="contain"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <TeamMemberTitle
              className="hidden md:block"
              name={name}
              role={role}
            />
            <div className="body-xs text-gray-500 mt-8 md:mt-0">
              {description?.data?.childMdx && (
                <MDXComponent type="no-style">
                  {description.data.childMdx.body}
                </MDXComponent>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
