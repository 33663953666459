module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-mdx@3.20.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@4.11.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo/apps/web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-intl@3.0.2_gatsby@4.11.1_react@17.0.2_typescript@4.4.4/node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["pl","en"],"defaultLanguage":"pl","path":"/opt/buildhome/repo/apps/web/intl","redirect":false,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-120},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-image@2.2.0_@babel+core@7.12.13_gatsby-plugin-sharp@4.2.0_gatsby-source-filesys_mwm3ptwpsp5ju5jzs57jtmulf4/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@4.25.0_gatsby@4.11.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W83BJWM","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.2.0_gatsby@4.11.1_graphql@15.10.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"web","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"167183b5c661cd829030f3df1d876cb0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
